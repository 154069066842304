import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import CaseStudyHeader from '../components/case-study-header'
import ShowcaseImage from '../components/showcase-image'
import TextBlockWithImage from '../components/text-block-with-image'
import SpacerRegular from '../components/spacer-regular'
import ShowcaseImageDouble from '../components/showcase-image-double'
import TextBlock from '../components/text-block'
import SpacerMedium from '../components/spacer-medium'

class CaseStudyFast extends React.Component {
  render() {
    const [header] = get(this, 'props.data.header.nodes')
    const [showcaseImage] = get(this, 'props.data.showcaseImage.nodes')
    const [featureOne] = get(this, 'props.data.featureOne.nodes')
    const [featureTwo] = get(this, 'props.data.featureTwo.nodes')
    const [showcaseDoubleOne] = get(this, 'props.data.showcaseDoubleOne.nodes')
    const [showcaseDoubleTwo] = get(this, 'props.data.showcaseDoubleTwo.nodes')
    const [everythingElse] = get(this, 'props.data.everythingElse.nodes')
    const [showcaseImageFinal] = get(this, 'props.data.showcaseImageFinal.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Case Study: Fast" />
        <CaseStudyHeader
          title={header.title}
          subtitle={header.subtitle}
          description={header.description.description}
        />
        <ShowcaseImage image={showcaseImage.image}/>
        <SpacerRegular/>
        <TextBlockWithImage
          title={featureOne.title}
          subtitle={featureOne.subtitle.subtitle}
          image={featureOne.image}
          leftImage={featureOne.leftImage}
        />
        <SpacerRegular/>
        <ShowcaseImageDouble
          imageLeft={showcaseDoubleOne.imageLeft}
          imageRight={showcaseDoubleOne.imageRight}
        />
        <SpacerRegular/>
        <TextBlockWithImage
          title={featureTwo.title}
          subtitle={featureTwo.subtitle.subtitle}
          image={featureTwo.image}
          leftImage={featureTwo.leftImage}
        />
        <SpacerRegular/>
        <ShowcaseImageDouble
          imageLeft={showcaseDoubleTwo.imageLeft}
          imageRight={showcaseDoubleTwo.imageRight}
        />
        <SpacerMedium/>
        <TextBlock 
          title={everythingElse.title}
          subtitle={everythingElse.subtitle.subtitle}
        />
        <SpacerRegular/>
        <ShowcaseImage image={showcaseImageFinal.image}/>
      </Layout>
    )
  }
}

export default CaseStudyFast

export const pageQuery = graphql`
  query CaseStudyFastQuery {
    header: allContentfulHeader(
      filter: { contentful_id: { eq: "1ZHNnEA1dyXHUElxIXRr23" } }
    ) {
      nodes {
        title
        subtitle
        description {
          description
        }
      }
    }
    showcaseImage: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "5xkF7iZHXrmp97E5hTNkMA" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    featureOne: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "19ifxR4m8Vximg2SPBtjZb" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    featureTwo: allContentfulTextBlockWithImage(
      filter: { contentful_id: { eq: "k7p61iLv7kPaQFyYtl5Y9" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
        leftImage
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    showcaseDoubleOne: allContentfulShowcaseImageDouble(
      filter: { contentful_id: { eq: "7sReeKnBRl7gyrv4zTs5L4" } }
    ) {
      nodes {
        imageLeft {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
        imageRight {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    showcaseDoubleTwo: allContentfulShowcaseImageDouble(
      filter: { contentful_id: { eq: "3APeP4Dmv1g7BAuW5gSmpt" } }
    ) {
      nodes {
        imageLeft {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
        imageRight {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    everythingElse: allContentfulTextBlock(
      filter: { contentful_id: { eq: "2nrXTZlhtUTdlOZt25F1oC" } }
    ) {
      nodes {
        title
        subtitle {
          subtitle
        }
      }
    }
    showcaseImageFinal: allContentfulShowcaseImage(
      filter: { contentful_id: { eq: "2RnOhvD0trHiSQaABGhz0P" } }
    ) {
      nodes {
        image {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
  }
`
